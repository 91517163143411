import React, {useEffect, useState} from 'react';
import './App.css';
// import './Fonts/fonts.css'
import RenderFirstPageOut from './RenderFirstPageOut'
import MobileIndex from './Mobile/Pages/MobileIndex';
import WXContactUs from './WXContactUs'
import WXCISCTestQAPage from './WXCISCTest/Pages/WXCISCTestQAPage';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import RootStore from './Store/RootStore'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { observer } from "mobx-react-lite"

const App = observer(() => {

  let fontStyles;

  // 根据操作系统选择字体样式文件
  if (navigator.userAgent.toLowerCase().includes('micromessenger') !== -1 ) {
    fontStyles = require('./Fonts/fontsmobile.css');
  } else if (navigator.userAgent.indexOf('Windows') !== -1) {
    fontStyles = require('./Fonts/fonts.css');
  } else if (navigator.userAgent.indexOf('Mac') !== -1) {
    fontStyles = require('./Fonts/fontsmobile.css');
  } else if (navigator.userAgent.indexOf('Linux') !== -1) {
    fontStyles = require('./Fonts/fonts.css');
  } else if (navigator.userAgent.match(/(iPad|iPhone|iPod)/g)) {
    fontStyles = require('./Fonts/fontsmobile.css');
  } else if (navigator.userAgent.match(/Android/g)) {
    fontStyles = require('./Fonts/fontsmobile.css');
  } else {
    fontStyles = require('./Fonts/fonts.css');
  }


  const [isMobile, setIsMobile] = useState(document.documentElement.clientWidth <=750);

  RootStore.clientWidth = document.documentElement.clientWidth;
  RootStore.clientHeight = document.documentElement.clientHeight;

  let doc = document;
  let win =window;
  let docEl = doc.documentElement,
  resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',

  recalc = function () {
    let clientWidth = docEl.clientWidth;
    let clientHeight = docEl.clientHeight;


    if (!clientWidth) return;
    if (clientWidth >750) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }

    // alert(docEl.style.fontSize)
  };

  if (!doc.addEventListener) return;
  win.addEventListener(resizeEvt, recalc, false);
  doc.addEventListener('DOMContentLoaded', recalc, false);

  // return (
  //   <div className="App">
  //     {!isMobile ? <RenderFirstPageOut pageState={RootStore.pageState}/> : <MobileIndex pageState={RootStore.pageState}/>}
  //   </div>
  // );


  const mainpage = 
    <div className="App">
      {!isMobile ? <RenderFirstPageOut pageState={RootStore.pageState}/> : <MobileIndex pageState={RootStore.pageState}/>}
    </div>
  ;

  const wxcontact = <div className="App"><WXContactUs /></div>

  const cisctestQA = <div className="App"><WXCISCTestQAPage /></div>



  const router = createBrowserRouter([
    {
      path: "/",
      element: mainpage,
    },
    {
      path: "/wxcontactus",
      element: wxcontact,
    },
    {
      path: "/ciscqatest",
      element: cisctestQA,
    },
  ]);


  return (
    <RouterProvider router={router} />
  );
})

export default App;
